import React, { FC } from "react";
import { Box, useTheme } from "@material-ui/core";
import { IndexNavbar, StaticFooter } from "@onpreo/components";

interface IndexContainerProps {
    logo?: string;
    noFooter?: boolean;
}

const IndexContainer: FC<IndexContainerProps> = props => {
    const theme = useTheme();
    return (
        <Box
            style={{
                minHeight: "100vh",
                background: "url('/imgs/bgn.jpg') no-repeat",
                backgroundSize: "cover",
                backgroundColor: theme.palette.primary.main + "ee",
                backgroundBlendMode: "multiply"
            }}
        >
            <IndexNavbar logo={{ imgSrc: props?.logo ?? "/logo.png", imgAlt: "onpreo" }} />
            <Box style={{ height: 360, backgroundColor: "#F7FAFC" }}>{props.children}</Box>
            {!props?.noFooter && <StaticFooter dark={true} />}
        </Box>
    );
};

export default IndexContainer;
